<template>
  <div class="col-12 d-flex flex-column justify-content-center text-center">
    <div class="bg-white border rounded p-2 m-3">
      <h4>Passo 1 - Selecionar pessoa</h4>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Filtrar por nome ou email"
            @keyup="filtraAlunos"
          />
        </div>

        <div class="col-sm-12 col-md-12 col-lg-8 mb-1">
          <span
            v-for="e in fastSteps.step1.pessoas"
            :key="e.id_pessoa"
            class="badge b-form-tag d-inline-flex align-items-baseline mw-100 mr-1 badge-secondary p-2"
          >
            <span class="b-form-tag-content flex-grow-1 text-truncate">
              {{ e.first_name + " " + e.last_name }}
            </span>
            <button
              type="button"
              class="close b-form-tag-remove"
              @click.prevent="removePessoa(e)"
            >
              ×
            </button>
          </span>
        </div>

        <div class="col-12 table-responsive mt-2">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th><small class="font-weight-bold">Nome</small></th>
                <th><small class="font-weight-bold">Email</small></th>
                <th class="text-center">
                  <small class="font-weight-bold">Aceito</small>
                </th>
                <th class="text-center">
                  <small class="font-weight-bold">Ativo</small>
                </th>
                <th class="text-center">
                  <small class="font-weight-bold">Adicionado em</small>
                </th>
                <th class="text-center">
                  <small class="font-weight-bold">Ações</small>
                </th>
              </tr>
            </thead>
            <tbody v-if="fastPessoasLoading">
              <tr>
                <td colspan="6" class="text-center">
                  <b-icon icon="gear-fill" animation="spin" /> Carregando
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="fastPessoasPaginacao.length">
              <tr v-for="(e, index) in fastPessoasPaginacao" :key="index">
                <td class="align-middle">
                  <small>{{ e.first_name + " " + e.last_name }}</small>
                </td>
                <td class="align-middle">
                  <small>{{ e.email }}</small>
                </td>
                <td class="align-middle text-center">
                  <small v-if="e.ativo == 'S'" class="badge badge-success mr-2"
                    >sim</small
                  >
                  <small v-else class="badge badge-danger mr-2">não</small>
                </td>
                <td class="align-middle text-center">
                  <small v-if="e.ativo == 'S'" class="badge badge-success mr-2"
                    >sim</small
                  >
                  <small v-else class="badge badge-danger mr-2">Não</small>
                </td>
                <td class="align-middle text-center">
                  <small v-if="e.data_convite_envio">{{
                    new Date(e.data_convite_envio).toLocaleDateString()
                  }}</small>
                  <small v-else>Sem registro</small>
                </td>
                <td class="text-center align-middle">
                  <div v-if="e.id_pessoa">
                    <a
                      v-if="!verificaPessoaAdicionada(e)"
                      @click="adicionaPessoa(e)"
                      class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1"
                    >
                      <small class="text-white">+ Adicionar</small>
                    </a>
                  </div>
                  <span v-else class="text-danger">
                    <small>Pessoa não identificada</small>
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6" class="text-center">
                  Nenhum aluno cadastrado na plataforma
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-center mt-4">
          <Pagination
            :page-size="5"
            :items="fastPessoasFiltro"
            @changePage="fastPessoasPaginacao = $event"
          />
        </div>
      </div>
    </div>
    <div class="bg-white border rounded p-2 m-3">
      <h4>Passo 2 - Selecionar itens</h4>
      <div class="col-12 p-4">
        <div class="row border rounded p-2">
          <div class="w-100">
            <h5 class="text-secondary">Criar novo item</h5>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-5">
            <label>Nome do item</label>
            <input
              v-model="fastItemCobrancaNovo.nome_item"
              class="form-control"
            />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-3">
            <label>Valor do item</label>
            <money
              v-model="fastItemCobrancaNovo.valor_item"
              class="form-control"
            />
          </div>
          <div
            class="col-sm-12 col-md-12 col-lg-4 mt-4 d-flex align-items-center justify-content-center"
          >
            <button
              class="btn btn-sm btn-primary"
              @click="criaNovoItemCobranca()"
            >
              <small>+ Criar novo item</small>
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="w-100">
            <h5 class="text-secondary">Itens cadastrados</h5>
          </div>
          <div class="col-12 table-responsive mt-2">
            <table class="table table-sm">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>
                    <small class="font-weight-bold">Item de cobrança</small>
                  </th>
                  <th>
                    <small class="font-weight-bold">Valor padrão</small>
                  </th>
                  <th class="text-center">
                    <small class="font-weight-bold">Ações</small>
                  </th>
                </tr>
              </thead>
              <tbody v-if="fastItensCobranca.loading">
                <tr>
                  <td colspan="3" class="text-center">Carregando...</td>
                </tr>
              </tbody>
              <tbody v-else-if="fastItensCobranca.total.length">
                <tr
                  v-for="e in fastItensCobranca.total"
                  :key="e.id_item_cobranca"
                >
                  <td class="align-middle text-center">
                    {{ e.nome_item }}
                  </td>
                  <td class="align-middle text-center">
                    {{ formataPreco(e.valor_item) }}
                  </td>
                  <td class="text-center align-middle">
                    <button
                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                      @click="adicionaItemCobranca(e)"
                    >
                      <small>+ Adicionar</small>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center">
                    Nenhum item cadastrado
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(i, index) in fastSteps.step2.itens"
              :key="index"
            >
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <label>Nome do item</label>
                  <input :value="i.nome_item" class="form-control" readonly />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-2">
                  <label>Valor</label>
                  <money
                    class="form-control"
                    v-model="i.valor_item"
                    @keyup.native="alteraValorTotalItens()"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-2">
                  <label>Quantidade</label>
                  <input
                    v-model="i.quantidade"
                    type="number"
                    class="form-control"
                    @change="alteraValorTotalItens()"
                  />
                </div>
                <div
                  class="col-sm-12 col-md-12 col-lg-2 d-flex justify-content-center align-items-end"
                >
                  <button
                    class="btn btn-sm btn-danger"
                    @click="removeItemCobranca(i)"
                  >
                    <small>- Remover</small>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div v-if="!fastSteps.step2.itens.length" class="text-center">
            <h6 class="text-secondary">Nenhum item adicionado</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white border rounded p-2 m-3">
      <h4>Passo 3 - Selecionar pagamento</h4>
      <div class="card-body">
        <div class="row mt-2 mb-2">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <h4 class="text-secondary">Gateway de pagamento</h4>
            <select v-if="fastSteps.step3.gateway.loading" class="form-control">
              <option value="">Carregando gateways...</option>
            </select>
            <select
              v-else-if="fastSecretariaGatewayPagamento.length"
              v-model="fastSteps.step3.gateway.id_pagamento_gateway"
              class="form-control"
              @change="verificaConfiguracaoPagamento()"
            >
              <option :value="0">
                -- Selecione um gateway de pagamento --
              </option>
              <option
                v-for="gateway in fastSecretariaGatewayPagamento.filter(
                  (i) => i.disponivel
                )"
                :key="gateway.id_pagamento_gateway"
                :value="gateway.id_pagamento_gateway"
              >
                {{ gateway.nome_gateway }}
              </option>
            </select>
            <select v-else class="form-control">
              <option value="">
                -- Nenhum gateway de pagamento encontrado --
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <h4 class="text-secondary">Tipo do invoice</h4>
            <select
              v-model="fastSteps.step3.tipo_invoice"
              class="form-control"
              disabled
            >
              <option
                v-for="tipoInvoice in fastTipoInvoice"
                :key="tipoInvoice.idTipoInvoice"
                :value="tipoInvoice.idTipoInvoice"
              >
                {{ tipoInvoice.nomeTipoInvoice }}
              </option>
            </select>
          </div>
          <div
            v-if="fastSteps.step3.gateway.id_pagamento_gateway"
            class="col-sm-12 col-md-12 col-lg-6"
          >
            <h4 class="text-secondary">Plano de pagamento</h4>
            <select v-if="fastSteps.step3.plano.loading" class="form-control">
              <option value="">Carregando planos...</option>
            </select>
            <select
              v-else-if="fastSecretariaPlanoPagamento.length"
              v-model="fastSteps.step3.plano.id_plano_pagamento"
              class="form-control"
              @change="verificaConfiguracaoPagamento()"
            >
              <option :value="0">-- Selecione um plano de pagamento --</option>
              <option
                v-for="plano in fastSecretariaPlanoPagamento"
                :key="plano.id_plano_pagamento"
                :value="plano.id_plano_pagamento"
              >
                {{ plano.nome_plano }}
              </option>
            </select>
            <select v-else class="form-control">
              <option value="">
                -- Nenhum plano de pagamento encontrado --
              </option>
            </select>
          </div>
          <div
            v-if="fastSteps.step3.gateway.id_pagamento_gateway"
            class="col-sm-12 col-md-12 col-lg-6"
          >
            <h4 class="text-secondary">Tipo de pagamento</h4>
            <select v-if="fastSteps.step3.tipo.loading" class="form-control">
              <option value="">Carregando tipos...</option>
            </select>
            <select
              v-else-if="fastSecretariaTipoPagamento.length"
              v-model="fastSteps.step3.tipo.id_tipo_pagamento"
              class="form-control"
              @change="verificaConfiguracaoPagamento()"
            >
              <option :value="0">-- Selecione um tipo de pagamento --</option>
              <option
                v-for="tipo in fastSecretariaTipoPagamento"
                :key="tipo.id_tipo_pagamento"
                :value="tipo.id_tipo_pagamento"
              >
                {{ tipo.nome_tipo_pagamento }}
              </option>
            </select>
            <select v-else class="form-control">
              <option value="">
                -- Nenhum tipo de pagamento encontrado --
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-4 mb-2">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row mt-2">
              <div class="col-12">
                <label>Observações</label>
                <textarea
                  class="form-control"
                  v-model="fastSteps.step3.invoice.obs"
                  maxlength="100"
                ></textarea>
              </div>
            </div>
            <!-- <div class="row mt-4 d-none">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <h4 class="text-secondary">
                  Schema de comissão
                  <span class="badge badge-danger"
                    >não ativado para geração de itens de cobrança</span
                  >
                </h4>
              </div>
            </div> -->
            <!-- <div class="row mt-4 d-none">
              <div
                v-if="comissaoHabilitada()"
                class="col-sm-12 col-md-12 col-lg-12"
              >
                <div class="row mb-4">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h4 class="text-secondary">Schema de comissão</h4>
                    <select
                      v-if="fastComissaoSchema.total.length"
                      class="form-control"
                      @change="getComissaoSchemaDeterminacoes"
                    >
                      <option value="0" selected>
                        -- Nenhum schema de comissão selecionado --
                      </option>
                      <option
                        v-for="c in fastComissaoSchema.total"
                        :key="c.id_comissao_schema"
                        :value="c.id_comissao_schema"
                      >
                        {{ c.nome_schema_comissao }}
                      </option>
                    </select>
                    <select v-else class="form-control">
                      <option value="">
                        -- Nenhum schema de comissão cadastrado --
                      </option>
                    </select>
                  </div>
                  <div
                    v-if="!fastComissaoSchemaDeterminacoes.loading"
                    class="col-sm-12 col-md-12 col-lg-12"
                  >
                    <div
                      v-if="fastComissaoSchemaDeterminacoes.loading"
                      class="row"
                    >
                      <div class="col-12 mt-4 text-center">
                        <span>Carregando itens...</span>
                      </div>
                    </div>
                    <div
                      v-else-if="fastComissaoSchemaDeterminacoes.total.length"
                      class="row"
                    >
                      <div class="col-12 table-responsive mt-4">
                        <table class="table table-sm table-bordered">
                          <thead class="thead-dark">
                            <tr class="text-center">
                              <th>
                                <small class="font-weight-bold"
                                  >Tipo determinação</small
                                >
                              </th>
                              <th>
                                <small class="font-weight-bold">Curso</small>
                              </th>
                              <th>
                                <small class="font-weight-bold">Comissão</small>
                              </th>
                              <th>
                                <small class="font-weight-bold"
                                  >Comissão parcela</small
                                >
                              </th>
                              <th>
                                <small class="font-weight-bold"
                                  >Comissão total</small
                                >
                              </th>
                              <th>
                                <small class="font-weight-bold"></small>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                e, index
                              ) in fastComissaoSchemaDeterminacoes.total"
                              :key="index"
                            >
                              <td
                                v-if="e.id_pessoa"
                                class="align-middle text-center"
                              >
                                Pessoa específica
                              </td>
                              <td
                                v-else-if="e.id_funcao_pessoa"
                                class="align-middle text-center"
                              >
                                {{ e.nome_funcao_pessoa }}
                              </td>
                              <td
                                v-else
                                class="align-middle text-center text-danger"
                              >
                                Não configurado
                              </td>

                              <td
                                v-if="e.id_curso"
                                class="align-middle text-center"
                              >
                                {{
                                  e.nome_curso ? e.nome_curso : e.nome_curso_mae
                                }}
                              </td>
                              <td
                                v-else-if="e.id_secretaria_curso"
                                class="align-middle text-center"
                              >
                                {{ e.nome_curso_secretaria }}
                              </td>
                              <td
                                v-else-if="e.id_secretaria_curso_programa"
                                class="align-middle text-center"
                              >
                                {{ e.sigla_programa }}
                              </td>
                              <td
                                v-else-if="
                                  e.id_secretaria_curso_programa_unidade
                                "
                                class="align-middle text-center"
                              >
                                {{ e.nome_curso_unidade }}
                              </td>
                              <td v-else class="align-middle text-center">
                                Todos
                              </td>

                              <td
                                v-if="e.percentual && e.percentual > 0"
                                class="align-middle text-center"
                              >
                                {{ e.percentual }}%
                              </td>
                              <td
                                v-else-if="e.valor && e.valor > 0"
                                class="align-middle text-center"
                              >
                                R$ {{ formataPreco(e.valor) }}
                              </td>
                              <td
                                v-else
                                class="align-middle text-center text-danger"
                              >
                                Não configurado
                              </td>

                              <td class="align-middle text-center">
                                <span v-if="e.valor_comissao_parcela">
                                  R$
                                  {{ formataPreco(e.valor_comissao_parcela) }}
                                </span>
                              </td>

                              <td class="align-middle text-center">
                                <span v-if="e.valor_comissao_total">
                                  R$ {{ formataPreco(e.valor_comissao_total) }}
                                </span>
                              </td>

                              <td
                                v-if="e.id_pessoa"
                                class="align-middle text-center"
                              >
                                {{
                                  e.nome_razao +
                                  " " +
                                  e.sobrenome_fantasia +
                                  " (" +
                                  e.email +
                                  ")"
                                }}
                              </td>
                              <td
                                v-else-if="e.id_funcao_pessoa"
                                class="align-middle text-center"
                              >
                                <select
                                  v-if="e.pessoas.length"
                                  class="form-control"
                                >
                                  <option value="" class="text-center">
                                    -- Selecione uma pessoa com a função
                                    {{ e.nome_funcao_pessoa }} --
                                  </option>
                                  <option
                                    v-for="p in e.pessoas"
                                    :key="p.id_pessoa"
                                    :value="p.id_pessoa"
                                  >
                                    {{
                                      p.nome_razao +
                                      " " +
                                      p.sobrenome_fantasia +
                                      " (" +
                                      p.email +
                                      ")"
                                    }}
                                  </option>
                                </select>
                                <span v-else class="text-danger">
                                  Nenhuma pessoa cadastrada com essa função
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div v-else class="col-12 mt-4 text-center">
                      <span>Nenhum item adicionado</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="col-sm-12 col-md-12 col-lg-12">
                <h4 class="text-secondary">
                  Schema de comissão
                  <span class="badge badge-danger"
                    >Não aplicável a este item</span
                  >
                </h4>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white border rounded p-2 m-3">
      <h4>Passo 4 - Revisar cobrança</h4>
      <div
        v-if="
          fastSteps.step3.gateway.id_pagamento_gateway &&
          fastSteps.step3.plano.id_plano_pagamento &&
          fastSteps.step3.tipo.id_tipo_pagamento
        "
        class="row my-2"
      >
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="col-sm-12 col-md-12 col-lg-12 my-2">
            <div class="d-flex align-items-center">
              Tipo de pagamento:
              <h6 class="mx-2 my-0">
                {{ fastCheckoutData.tipo_pagamento }}
              </h6>
            </div>
            <div class="d-flex align-items-center">
              Valor total:
              <h6 class="mx-2 my-0">
                {{ fastCheckoutData.valor_total_str }}
              </h6>
            </div>
          </div>
          <div class="col-12 table-responsive">
            <table class="table table-sm">
              <thead class="thead-dark">
                <tr>
                  <th class="text-center">Parcela</th>
                  <th class="text-center">Valor</th>
                  <th class="text-center">Vencimento</th>
                </tr>
              </thead>
              <tbody v-if="fastCheckoutData.loading">
                <tr>
                  <td colspan="6" class="text-center">
                    <span>
                      <b-icon icon="gear-fill" animation="spin" />
                      Carregando parcelas
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="fastCheckoutData.parcelas.length">
                <tr
                  v-for="(parcela, index) in fastCheckoutData.parcelas"
                  :key="index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ parcela.valor_parcela_str }}
                  </td>
                  <td>
                    {{ parcela.data_vencimento }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6" class="text-center">
                    <span>Nenhuma parcela gerada</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="col-sm-12 col-md-12 col-lg-12 my-2 text-center d-flex justify-content-center"
      >
        <!-- <button
            v-if="
              fastSteps.step3.gateway.id_pagamento_gateway &&
              fastSteps.step3.plano.id_plano_pagamento &&
              fastSteps.step3.tipo.id_tipo_pagamento
            "
            class="btn btn-sm btn-primary"
            @click="revisarCobranca"
          >
            <small>Gerar cobrança</small>
          </button> -->
        <!-- v-else -->
        <button
          class="btn btn-sm btn-primary"
          @click="revisarCobranca()"
        >
          <small>Gerar cobrança</small>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoTesourariaGeracaoCobrancas",
  components: {
    Pagination,
  },
  mixins: [methods],
  data: function () {
    return {
      fastSteps: {
        currentStep: 0,
        step1: {
          pessoas: [],
        },
        step2: {
          itens: [],
          total: 0.0,
        },
        step3: {
          plano: {
            id_plano_pagamento: 0,
            nome_plano: "",
            obs: "",
            periocidade_cada_unidade: "",
            dia_mes: "",
            tempo_determinado: "",
            parcelas: [],
            loading: true,
          },
          tipo: {
            forma_pagamento: "",
            id_tipo_pagamento: 0,
            nome_tipo_pagamento: "",
            id_plano_conta: "",
            loading: true,
          },
          gateway: { id_pagamento_gateway: 0, loading: true },
          tipo_invoice: "I",
          invoice: {
            id_plataforma: 0,
            id_invoice: 0,
            data_registro: "",
            id_usuario_gerou: 0,
            id_matricula: 0,
            nome_aluno: "",
            nome_responsavel: "",
            cpf_responsavel: "",
            cpf_aluno: "",
            cep_responsavel: "",
            endereco_responsavel_numero: "",
            endereco_responsavel_complemento: "",
            subtotal: 0.0,
            desconto_total: 0.0,
            acrescimo: 0.0,
            total: 0.0,
            ativo: "",
            cancelado: "",
            motivo_cancelamento: "",
            id_usuario_cancelamento: 0,
            data_cancelamento: "",
            obs: "Cobrança padrão",
            id_plano_pagamento: "",
            taxa_boleto: 0.0,
            taxa_cartao: 0.0,
            outras_taxas: 0.0,
            id_gateway: 0,
            id_pessoa_aluno: 0,
            tipo: "",
            parcelas: [],
          },
        },
      },
      fastPessoasTotal: [],
      fastPessoasFiltro: [],
      fastPessoasPaginacao: [],
      fastPessoasLoading: [],
      fastSecretariaPlanoPagamento: [],
      fastSecretariaTipoPagamento: [],
      fastSecretariaGatewayPagamento: [],
      fastTipoInvoice: [
        { idTipoInvoice: "I", nomeTipoInvoice: "Item de cobrança" },
        { idTipoInvoice: "C", nomeTipoInvoice: "Curso" },
        { idTipoInvoice: "M", nomeTipoInvoice: "Matrícula" },
      ],
      fastItensCobranca: {
        total: [],
        loading: true,
      },
      fastItemCobrancaNovo: {
        id_item_cobranca: 0,
        nome_item: "",
        valor_item: 0.0,
        quantidade: 1,
        id_plataforma: this.$route.params.id_plataforma,
      },
      fastCheckoutData: {
        loading: true,
      },
      fastGerarCobranca: {
        id_plataforma: 0,
        id_pessoa_responsavel: 0,
        id_pessoa_aluno: 0,
        id_pagamento_gateway: 0,
        id_plano_pagamento: 0,
        id_tipo_pagamento: 0,
        invoice_obs: "",
        data_vencimento_primeira_parcela: new Date(),
        itens: [
          {
            id_item_cobranca: 0,
            nome_item: "",
            valor: 0,
            quantidade: 0,
          },
        ],
        confirmar_geracao: false,
        tipo_invoice: "",
        id_matricula: 0,
        // comissoes: [
        //   {
        //     id_comissao_schema: 0,
        //     id_determinacao: 0,
        //     id_pessoa_fastead: 0,
        //     id_pessoa_gateway: '',
        //     comissao_porcentagem: 0,
        //     comissao_valor: 0,
        //   },
        // ],
        // link_pagamento: {
        //   tipos_pagamento: [string],
        //   parcelas_maximas: 0,
        //   recorrencia: true,
        //   recorrencia_semanal: true,
        //   recorrencia_mensal: true,
        //   recorrencia_anual: true,
        // },
      },
    };
  },
  mounted: function () {
    this.getUsuariosPlataforma(this.$route.params.id_plataforma);
    this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);
    this.getSecretariaGatewaysPagamento(this.$route.params.id_plataforma);
    this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
    this.getItensCobranca();
  },
  methods: {
    async getUsuariosPlataforma(id_plataforma) {
      this.fastPessoasLoading = true;
      this.promiseGetFastApi(
        "api/fast_plataforma_usuario/lista_usuarios_plataforma",
        `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=`
      )
        .then((obj) => {
          console.log("getUsuariosPlataforma", obj);
          if (obj.length) {
            this.fastPessoasTotal = obj;
            this.fastPessoasFiltro = obj;
          } else {
            this.fastPessoasTotal = [];
            this.fastPessoasFiltro = [];
          }
          this.fastPessoasLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.exibeToasty("Erro ao buscar alunos", "error");
          this.fastPessoasLoading = false;
        });
    },
    getSecretariaPlanoPagamento(id_plataforma) {
      if (!this.fastSecretariaPlanoPagamento.length) {
        this.fastSteps.step3.plano.loading = true;
        this.promiseGetFastApi(
          "api/fast_tesouraria_plano_pagamento/lista",
          "id_plataforma=" + id_plataforma
        )
          .then((obj) => {
            console.log("getSecretariaPlanoPagamento", obj);
            if (obj.length) {
              obj = obj.filter((p) => {
                if (p.ativo) return true;
              });
              this.fastSecretariaPlanoPagamento = obj;
            } else this.fastSecretariaPlanoPagamento = [];
            this.fastSteps.step3.plano.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.fastSteps.step3.plano.loading = false;
          });
      }
    },
    getSecretariaGatewaysPagamento(id_plataforma) {
      if (!this.fastSecretariaGatewayPagamento.length) {
        this.fastSteps.step3.gateway.loading = true;
        this.promiseGetFastApi(
          "api/fast_pagamento_gateway/lista_disponiveis",
          "id_plataforma=" + id_plataforma
        )
          .then((obj) => {
            console.log("getSecretariaPlanoPagamento", obj);
            if (obj.length) {
              this.fastSecretariaGatewayPagamento = obj;
            } else this.fastSecretariaGatewayPagamento = [];
            this.fastSteps.step3.gateway.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.fastSteps.step3.gateway.loading = false;
          });
      }
    },
    getSecretariaTipoPagamento(id_plataforma) {
      if (!this.fastSecretariaTipoPagamento.length) {
        this.fastSteps.step3.tipo.loading = true;
        this.promiseGetFastApi(
          "api/fast_tesouraria_tipo_pagamento/lista",
          "id_plataforma=" + id_plataforma
        )
          .then((obj) => {
            console.log("getSecretariaTipoPagamento", obj);
            if (obj.length) {
              // Filtra somente débito
              this.fastSecretariaTipoPagamento = obj.filter((p) => {
                if (p.operador == "D") return true;
              });
              //this.fastSecretariaTipoPagamento = obj
            } else {
              this.fastSecretariaTipoPagamento = [];
            }

            this.fastSteps.step3.tipo.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.fastSteps.step3.tipo.loading = false;
          });
      }
    },
    filtraAlunos(e) {
      let text = e.target.value;
      this.fastPessoasFiltro = this.fastPessoasTotal.filter((e) => {
        return (
          e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" ||
          e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" ||
          e.email.toLowerCase().indexOf(text.toLowerCase()) != "-1"
        );
      });
    },
    verificaPessoaAdicionada(pessoa) {
      let pessoaAdicionado = false;
      this.fastSteps.step1.pessoas.forEach((e) => {
        if (e.id_pessoa == pessoa.id_pessoa) pessoaAdicionado = true;
      });
      return pessoaAdicionado;
    },
    adicionaPessoa(pessoa) {
      // Restringe a somente uma pessoa por enquanto
      if (this.fastSteps.step1.pessoas.length) {
        this.exibeToasty(
          "O sistema está restrito a somente uma pessoa",
          "error"
        );
      } else {
        if (!this.verificaPessoaAdicionada(pessoa))
          this.fastSteps.step1.pessoas.push(pessoa);
      }
    },
    removePessoa(pessoa) {
      this.fastSteps.step1.pessoas = this.fastSteps.step1.pessoas.filter(
        (p) => p.id_pessoa != pessoa.id_pessoa
      );
    },
    async getItensCobranca() {
      this.fastItensCobranca.loading = true;
      this.promiseGetFastApi(
        "api/fast_tesouraria_itens_cobranca/lista",
        "id_plataforma=" + this.$route.params.id_plataforma
      )
        .then((res) => {
          console.log("getItensCobranca", res);
          if (res.length) this.fastItensCobranca.total = res;
          else this.fastItensCobranca.total = [];

          this.fastItensCobranca.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.fastItensCobranca.loading = false;
        });
    },
    alteraValorTotalItens() {
      this.fastSteps.step2.total = 0.0;
      this.fastSteps.step2.itens.forEach((e) => {
        if (!e.quantidade || e.quantidade == "0") {
          console.log("e.quantidade", e.quantidade);
          e.quantidade = 1;
        }
        if (!e.valor_item) e.valor_item = 0.01;
        this.fastSteps.step2.total += e.valor_item * e.quantidade;
      });
      console.log("this.fastSteps.step2.total", this.fastSteps.step2.total);
    },
    verificaItemAdicionado(item) {
      let itemAdicionado = false;
      this.fastSteps.step2.itens.forEach((e) => {
        if (e.id_item_cobranca == item.id_item_cobranca) itemAdicionado = true;
      });
      return itemAdicionado;
    },
    adicionaItemCobranca(item) {
      if (!this.verificaItemAdicionado(item)) {
        this.fastSteps.step2.itens.push(item);
      } else {
        this.fastSteps.step2.itens.forEach((e) => {
          if (e.id_item_cobranca == item.id_item_cobranca) e.quantidade++;
        });
      }
      this.alteraValorTotalItens();
      this.exibeToasty("Item adicionado", "success");
    },
    removeItemCobranca(item) {
      this.fastSteps.step2.itens = this.fastSteps.step2.itens.filter(
        (p) => p.id_item_cobranca != item.id_item_cobranca
      );
      this.alteraValorTotalItens();
    },
    async criaNovoItemCobranca() {
      let erros = [];
      this.fastItemCobrancaNovo.id_plataforma =
        this.$route.params.id_plataforma;
      if (!this.fastItemCobrancaNovo.nome_item)
        erros.push("O nome do item é obrigatório");
      if (!this.fastItemCobrancaNovo.valor_item)
        erros.push("O valor do item é obrigatório");
      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        this.promiseInserirFastApi(
          this.fastItemCobrancaNovo,
          "fast_tesouraria_itens_cobranca"
        )
          .then((res) => {
            console.log("criaNovoItemCobranca", res);
            if (res.length) {
              this.fastItemCobrancaNovo = {
                id_item_cobranca: 0,
                nome_item: "",
                valor_item: 0.0,
                quantidade: 1,
                id_plataforma: this.fastItemCobrancaNovo.id_plataforma,
              };
              this.fastItensCobranca.total.push(res[0]);
              this.adicionaItemCobranca(res[0]);
            } else {
              this.exibeToasty("Erro ao criar item de cobrança", "error");
            }
          })
          .catch((e) => {
            console.log(e);
            this.exibeToasty("Erro ao criar item de cobrança", "error");
          });
      }
    },
    verificaConfiguracaoPagamento() {
      if (
        this.fastSteps.step3.gateway.id_pagamento_gateway &&
        this.fastSteps.step3.tipo.id_tipo_pagamento &&
        this.fastSteps.step3.plano.id_plano_pagamento
      ) {
        let erros = [];

        // Busca o tipo selecionado
        this.fastSecretariaTipoPagamento.forEach((p) => {
          if (
            p.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento
          ) {
            this.fastSteps.step3.tipo.forma_pagamento = p.forma_pagamento;
            this.fastSteps.step3.tipo.id_plano_conta = p.id_plano_conta;
            this.fastSteps.step3.tipo.id_plataforma = p.id_plataforma;
            this.fastSteps.step3.tipo.nome_tipo_pagamento =
              p.nome_tipo_pagamento;
            this.fastSteps.step3.tipo.operador = p.operador;
          }
        });
        if (!this.fastSteps.step3.tipo.forma_pagamento)
          erros.push("Tipo de pagamento não identificado");
        // Busca o plano selecionado
        this.fastSecretariaPlanoPagamento.forEach((p) => {
          if (
            p.id_plano_pagamento ==
            this.fastSteps.step3.plano.id_plano_pagamento
          ) {
            this.fastSteps.step3.plano.nome_plano = p.nome_plano;
            this.fastSteps.step3.plano.obs = p.obs;
            this.fastSteps.step3.plano.periocidade_cada_unidade =
              p.periocidade_cada_unidade;
            this.fastSteps.step3.plano.dia_mes = p.dia_mes;
            this.fastSteps.step3.plano.tempo_determinado = p.tempo_determinado;
            this.fastSteps.step3.plano.parcelas = [];
          }
        });
        if (!this.fastSteps.step3.plano.nome_plano)
          erros.push("Plano de pagamento não identificado");
        if (
          !this.fastSteps.step3.plano.tempo_determinado ||
          this.fastSteps.step3.plano.tempo_determinado < 1
        )
          erros.push(
            "Plano de pagamento para tempo indeterminado não está ativo"
          );

        if (erros.length) {
          erros.forEach((e) => this.exibeToasty(e, "error"));
          this.fastSteps.step3.plano.id_plano_pagamento = 0;
          this.fastSteps.step3.tipo.id_tipo_pagamento = 0;
        } else {
          this.aplicaAjusteValor();
          this.validaTerceiroPasso();
        }
      }
    },
    formataFormaPagamento(forma_pagamento) {
      switch (forma_pagamento) {
        case "C":
          return "Cartão";
        case "B":
          return "Boleto bancário";
        default:
          break;
      }
    },
    aplicaAjusteValor() {
      let erros = [];

      // Ajustes do valor total, o subtotal nunca é alterado
      let valor_total = 0.0;
      valor_total = this.fastSteps.step2.total;
      this.fastSteps.step3.invoice.subtotal = valor_total;

      if (this.fastSteps.step3.invoice.acrescimo == "")
        this.fastSteps.step3.invoice.acrescimo = 0.0;
      if (this.fastSteps.step3.invoice.desconto_total == "")
        this.fastSteps.step3.invoice.desconto_total = 0.0;
      valor_total =
        parseFloat(this.fastSteps.step3.invoice.subtotal) +
        parseFloat(this.fastSteps.step3.invoice.acrescimo) -
        parseFloat(this.fastSteps.step3.invoice.desconto_total);

      let n_parcelas = 0;
      n_parcelas =
        this.fastSteps.step3.plano.tempo_determinado /
        this.fastSteps.step3.plano.periocidade_cada_unidade;

      // Dessa forma arredonda pra cima
      let valor_parcela = parseFloat(valor_total / n_parcelas);
      valor_parcela = this.arredondaCasasDecimais(valor_parcela);

      // Dessa forma gera incremento pra ser adicionado na primeira parcela
      //let valor_parcela = parseFloat(valor_total / n_parcelas).toFixed(2)
      //let parcelasSomadas = n_parcelas * parseFloat(valor_parcela)
      //let incremento = 0.00

      // Se existir taxas nas parcelas incrementa no valor total
      let taxa_boleto = 0.0;
      if (this.fastSteps.step3.invoice.taxa_boleto)
        taxa_boleto = parseFloat(this.fastSteps.step3.invoice.taxa_boleto);
      let taxa_cartao = 0.0;
      if (this.fastSteps.step3.invoice.taxa_cartao)
        taxa_cartao = parseFloat(this.fastSteps.step3.invoice.taxa_cartao);
      let outras_taxas = 0.0;
      if (this.fastSteps.step3.invoice.outras_taxas)
        outras_taxas = parseFloat(this.fastSteps.step3.invoice.outras_taxas);
      let taxas_parcela =
        taxa_boleto * n_parcelas +
        taxa_cartao * n_parcelas +
        outras_taxas * n_parcelas;

      this.fastSteps.step3.invoice.total =
        valor_parcela * n_parcelas + taxas_parcela;

      // Se a soma das parcelas não bater com o valor total original então é necessário calcular um incremento para adicionar no valor total e posteriormente na primeira parcela
      //if (parseFloat(this.fastSteps.step2.total) > parseFloat(parcelasSomadas).toFixed(2)) {
      //  incremento = parseFloat(this.fastSteps.step2.total) - parseFloat(parseFloat(parcelasSomadas).toFixed(2))
      //  this.fastSteps.step3.invoice.total += incremento
      //}

      // O mínimo valor da parcela é 5 (restrição do gerencianet)
      if (valor_parcela < 5)
        erros.push("O valor mínimo da parcela é de R$ 5,00");

      // Se o plano possui tempo determinado
      if (!this.fastSteps.step3.plano.tempo_determinado)
        erros.push("Plano sem tempo determinado não é suportado no momento");

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
        this.fastSteps.step3.plano.id_plano_pagamento = 0;
        this.fastSteps.step3.tipo.id_tipo_pagamento = 0;
      } else {
        let dataAtual = new Date();
        // Captura a data do servidor para cálculo das datas das parcelas
        this.getFastHoraServidor()
          .then(() => {
            // Evita problema de fevereiro
            dataAtual = this.$store.state.fastDataAtualServidor;
            if (dataAtual.getDate() > 28) {
              dataAtual.setMonth(dataAtual.getMonth() + 1);
              dataAtual.setDate(1);
            }
            if (this.dataPrimeiraParcela)
              dataAtual = new Date(
                this.dataPrimeiraParcela.split("-")[0] +
                  "/" +
                  this.dataPrimeiraParcela.split("-")[1] +
                  "/" +
                  this.dataPrimeiraParcela.split("-")[2]
              );
            return dataAtual;
          })
          .then((dataAtual) => {
            // Monta as parcelas
            this.fastSteps.step3.plano.parcelas = [];
            this.fastSteps.step3.invoice.parcelas = [];
            for (let index = 0; index < n_parcelas; index++) {
              // Se periodicidade for mês seta do vencimento por mês
              if (this.fastSteps.step3.plano.dia_mes == "M") {
                if (index) {
                  dataAtual.setMonth(
                    dataAtual.getMonth() +
                      this.fastSteps.step3.plano.periocidade_cada_unidade
                  );
                }
                // Se periodicidade for dia seta do vencimento por dia
              } else if (this.fastSteps.step3.plano.dia_mes == "D") {
                if (index)
                  dataAtual = this.addDays(
                    dataAtual,
                    this.fastSteps.step3.plano.periocidade_cada_unidade
                  );
              }

              // Cria objeto da parcela
              let objParcela = {
                parcela: index + 1,
                total: this.fastSteps.step3.invoice.total,
                valor_parcela: valor_parcela,
                datetime: dataAtual,
                data_formatada: dataAtual.toLocaleString("pt-BR").split(" ")[0],
                data_vencimento: dataAtual
                  .toLocaleString("pt-BR")
                  .split(" ")[0],
                acrescimo: 0.0,
                desconto: 0.0,
                taxa_boleto: taxa_boleto,
                taxa_cartao: taxa_cartao,
                outras_taxas: outras_taxas,
                nr_parcelas: n_parcelas,
              };

              // Se existir incremento adiciona na primeira parcela
              //if (incremento > 0 && index == 0)   objParcela.valor_parcela = parseFloat(valor_parcela) + parseFloat(incremento)

              // Adiciona objeto parcela no array de parcelas do plano e invoice
              this.fastSteps.step3.plano.parcelas.push(objParcela);
              this.fastSteps.step3.invoice.parcelas.push(objParcela);
            }

            // Chama as comissões novamente
            // this.getComissaoSchemas(this.$route.params.id_plataforma);
          })
          .catch((e) => this.exibeToasty(e, "error"));
      }
    },
    async validaTerceiroPasso() {
      return new Promise((resolve, reject) => {
        if (
          this.fastSteps.step3.plano.id_plano_pagamento &&
          this.fastSteps.step3.tipo.id_tipo_pagamento
        ) {
          this.fastSecretariaTipoPagamento.forEach((t) => {
            if (
              t.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento
            ) {
              this.fastSteps.step3.tipo.nome_tipo_pagamento =
                t.nome_tipo_pagamento;
              this.fastSteps.step3.tipo.forma_pagamento = t.forma_pagamento;
            }
          });

          // Valida pessoa
          this.fastSteps.step1.pessoas.forEach((p) => {
            if (!p.id_pessoa) {
              this.exibeToasty(
                `O usuário ${p.first_name} não possui um id_pessoa`,
                "error"
              );
            } else {
              console.log(
                "api/fast_plataforma_usuario/busca_pessoa_secretaria",
                p.id_pessoa
              );

              this.promiseGetFastApi(
                "api/fast_plataforma_usuario/busca_pessoa_secretaria",
                "id_pessoa=" +
                  p.id_pessoa +
                  "&id_plataforma=" +
                  this.$route.params.id_plataforma
              )
                .then((obj) => {
                  let objPessoa = JSON.parse(obj);
                  console.log("busca_pessoa_secretaria -> resposta", objPessoa);
                  if (objPessoa.id_pessoa) {
                    objPessoa.id_plataforma = this.$route.params.id_plataforma;

                    let erros = [];

                    if (objPessoa.cpf_cnpj) p.cpf_cnpj = objPessoa.cpf_cnpj;
                    else
                      erros.push(
                        `O usuário ${p.first_name} não possui CPF/CNPJ cadastrado`
                      );

                    if (objPessoa.cep) p.cep = objPessoa.cep;
                    else
                      erros.push(
                        `O usuário ${p.first_name} não possui endereço completo cadastrado`
                      );

                    if (objPessoa.datanascimento_abertura)
                      p.datanascimento_abertura =
                        objPessoa.datanascimento_abertura;
                    else
                      erros.push(
                        `O usuário ${p.first_name} não possui a data de nascimento cadastrada`
                      );

                    if (objPessoa.email) p.email = objPessoa.email;
                    else
                      erros.push(
                        `O usuário ${p.first_name} não possui email cadastrado`
                      );

                    if (objPessoa.telefone) p.telefone = objPessoa.telefone;
                    else
                      erros.push(
                        `O usuário ${p.first_name} não possui telefone cadastrado`
                      );

                    if (objPessoa.complemento)
                      p.complemento = objPessoa.complemento;
                    if (objPessoa.numero) p.numero = objPessoa.numero.trim();

                    (this.fastSteps.step3.invoice.cpf_responsavel = p.cpf_cnpj),
                      (this.fastSteps.step3.invoice.cep_responsavel = p.cep),
                      (this.fastSteps.step3.invoice.endereco_responsavel_numero =
                        p.numero),
                      (this.fastSteps.step3.invoice.endereco_responsavel_complemento =
                        p.complemento),
                      (this.fastSteps.step3.invoice.ativo = true);
                    this.fastSteps.step3.invoice.cpf_aluno = p.cpf_cnpj;
                    this.fastSteps.step3.invoice.id_pessoa_aluno = p.id_pessoa;
                    this.fastSteps.step3.invoice.id_pessoa_responsavel =
                      p.id_pessoa;
                    this.fastSteps.step3.invoice.id_plano_pagamento =
                      this.fastSteps.step3.plano.id_plano_pagamento;
                    this.fastSteps.step3.invoice.id_plataforma =
                      this.$route.params.id_plataforma;
                    this.fastSteps.step3.invoice.nome_aluno =
                      p.first_name + " " + p.last_name;
                    this.fastSteps.step3.invoice.nome_responsavel =
                      p.first_name + " " + p.last_name;

                    // Gerencianet
                    this.fastSteps.step3.invoice.id_gateway = 2;

                    if (erros.length) {
                      erros.forEach((e) => this.exibeToasty(e, "error"));
                      erros = [];
                      reject(false);
                      return false;
                    } else {
                      // this.revisarCobranca();
                    }
                  } else {
                    this.exibeToasty(
                      `Erro ao buscar informações do usuário ${p.first_name}`,
                      "error"
                    );
                    reject(false);
                    return false;
                  }
                })
                .catch((e) => {
                  this.exibeToasty(`${e}`, "error");
                  reject(false);
                  return false;
                });
            }
          });
        } else {
          this.exibeToasty("Escolha um plano e um tipo de pagamento", "error");
          reject(true);
          return false;
        }
      });
    },
    async revisarCobranca() {
      this.fastCheckoutData = {};
      let arrayItems = [];
      if (this.fastSteps.step3.tipo_invoice === "I") {
        arrayItems = this.fastSteps.step2.itens.map((item) => ({
          id_item_cobranca: item.id_item_cobranca,
          quantidade: item.quantidade,
        }));
      } else {
        arrayItems = this.fastSteps.step2.itens.map((item) => ({
          id_item_cobranca: item.id_item_cobranca,
          nome_item: item.nome_item,
          valor: item.valor_item,
          quantidade: item.quantidade,
        }));
      }
      let obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pessoa_responsavel:
          this.fastSteps.step3.invoice.id_pessoa_responsavel,
        id_pessoa_aluno: this.fastSteps.step3.invoice.id_pessoa_aluno,
        id_pagamento_gateway: this.fastSteps.step3.gateway.id_pagamento_gateway,
        id_plano_pagamento: this.fastSteps.step3.plano.id_plano_pagamento,
        id_tipo_pagamento: this.fastSteps.step3.tipo.id_tipo_pagamento,
        invoice_obs: this.fastSteps.step3.invoice.obs,
        data_vencimento_primeira_parcela: new Date(),
        itens: arrayItems,
        confirmar_geracao: true,
        tipo_invoice: this.fastSteps.step3.tipo_invoice,
        id_matricula: this.fastSteps.step3.invoice.id_matricula,
      };
      this.promisePostFastApi(obj, "api/fast_pagamento_gateway/criar_cobranca")
        .then((res) => {
          if (res.success === false || res.status === 400) {
            this.exibeToasty(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`,
              "error"
            );
          } else {
            this.exibeToasty(`Dados atualizados`, "success");
            this.fastCheckoutData = res;
            let tipo_pagamento = this.fastSecretariaTipoPagamento.filter(
              (i) => i.forma_pagamento === this.fastCheckoutData.tipo_pagamento
            );
            this.fastCheckoutData.tipo_pagamento =
              tipo_pagamento[0].nome_tipo_pagamento;
          }
          this.fastCheckoutData.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.fastCheckoutData.loading = false;
        });
    },
  },
};
</script>

<style>
.btn:disabled {
  opacity: 0.5;
  background-color: #fd7e14;
  border: none;
}
li {
  list-style-type: none;
}
</style>
